import { Injectable } from '@angular/core'
import { Network } from '@capacitor/network'
import { loadDependantByDate, Profile, selectDependantListLength, selectProfile } from '@events'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { from, Observable } from 'rxjs'
import { defaultIfEmpty, filter, map, switchMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class ShellGuard {
    constructor(private store: Store) {}

    canActivate(): Observable<boolean> {
        return from(Network.getStatus()).pipe(
            filter((status) => status.connected),
            switchMap(() => {
                this.getProfile().subscribe((profile: Profile) => {
                    this.store.dispatch(
                        loadDependantByDate({ guardianId: profile.id, date: moment().toDate() })
                    )
                })

                return this.hasChildList().pipe(filter((val) => !!val))
            }),
            defaultIfEmpty(true)
        )
    }

    private getProfile(): Observable<Profile> {
        return this.store.select(selectProfile).pipe(
            filter((profile) => !!profile),
            map((profile) => profile as Profile)
        )
    }

    private hasChildList(): Observable<boolean> {
        return this.store.select(selectDependantListLength).pipe(map((len) => len > 0))
    }
}
