import { markDate } from '@bh/design-system'
import { ActionReducer, createReducer, on } from '@ngrx/store'
import moment from 'moment'
import { ActivityFeedEntry, ActivityFeedMap, AttendanceEntry, MealEntry } from '../models/activity-feed'
import { ActivityFeedDomain } from '../models/activity-feed-domain'
import {
    CenterByDependentMap,
    Dependant,
    DependantInfo,
    DependentScheduleMap,
    DependentsDateMap,
    ScheduledDependent
} from '../models/dependant'
import { DateUtil } from '../utils/date-util'
import {
    loadActivityFeedDomainSuccess,
    loadActivityFeedSuccess,
    loadDependantSuccess,
    setPaginationCompleted,
    selectedDependant,
    loadDependantByDateSuccess
} from './dependant.actions'

export const dependantFeatureKey = 'dependant'

export interface DependantState {
    dependantList: Dependant[]
    centerIdCheckIn: CenterByDependentMap
    activityFeed: ActivityFeedMap
    activityFeedDomain: ActivityFeedDomain
    activityFeedDate: Date
    paginationCompleted: boolean
    withdrawnChildrenCount: number
    selectedDependant: Dependant | null
    dependantListDate: DependentsDateMap
    dependantListNextDate: DependantInfo[]
    centers: string[]
}

const initialState: DependantState = {
    dependantList: [],
    centerIdCheckIn: {},
    activityFeed: {},
    activityFeedDomain: {
        attributeList: [],
        domainList: [],
        progressionList: []
    },
    activityFeedDate: DateUtil.localToUtc(new Date()),
    paginationCompleted: false,
    withdrawnChildrenCount: 0,
    selectedDependant: null,
    dependantListDate: {},
    dependantListNextDate: [],
    centers: []
}

export const getDependentName: (dependent: Dependant) => string = (dep) => {
    return dep ? dep.firstName.toLowerCase() + dep.lastName.toLowerCase() : ''
}

const titleCase: (text: string) => string = (text) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
}

export const getUniqueActiveDependents: (
    dependents: Dependant[],
    selectedDate: Date
) => Dependant[] = (dependents, selectedDate: Date) => {
    const dependentMap = new Map<string, Dependant>()
    dependents.forEach((dep) => {
        const key = getDependentName(dep)
        const isNew = !dependentMap.has(key)

        const { lastStatusChange, schedule, status } = dep
        const ninetyDays = moment().subtract(90, 'd')
        const suspendedWithinTime = moment(lastStatusChange).isAfter(ninetyDays)
        const today = moment(selectedDate).format('dd')
        const scheduledToday = schedule.includes(today)
        const active = status === 'active' || status === 'scheduled'
        const suspended = status === 'suspended'

        const isActive = active || (suspended && suspendedWithinTime)
        const savedDependent = dependentMap.get(key)
        const isCurrentActive =
            (savedDependent?.status !== 'active' && (active || scheduledToday)) ||
            (savedDependent?.status === 'active' && active && scheduledToday)
        const isLatestEnrolledCentre = savedDependent
            && savedDependent.status === 'active'
            && savedDependent.schedule.some(s => schedule.includes(s))
            ? moment(dep.enrollmentDate).isSameOrAfter(savedDependent.enrollmentDate)
            : true
        const shouldAdd = ((isNew && isActive) || (!isNew && isCurrentActive)) && isLatestEnrolledCentre

        if (shouldAdd) {
            dependentMap.set(key, dep)
        }
    })

    return Array.from(dependentMap.values())
}

const getDependentUniqueKeyByStatus: (dependent: Dependant) => string = (dep) => {
    return `${getDependentName(dep)}_${dep.status}`
}

const shouldReplaceSavedDependent = (newDependent: Dependant, selectedDate: Date, savedDependent?: Dependant) => {
    const savedDependentStatusIsActive = savedDependent?.status === 'active'
    const newDependentStatusIsActive = newDependent.status === 'active'
    if (savedDependentStatusIsActive) {
        if (newDependentStatusIsActive) {
            return Dependant.getEarliestScheduleDay(
                newDependent.scheduledCenterIds, selectedDate
            ) <= Dependant.getEarliestScheduleDay(
                savedDependent.scheduledCenterIds, selectedDate
            )
        } else {
            return false
        }
    } else {
        const today = moment(selectedDate).format('dd')
        const scheduledToday = newDependent.schedule.includes(today)
        return newDependentStatusIsActive || scheduledToday
    }
}

export const getUniqueActiveDependentsByStatus: (
    dependents: Dependant[],
    selectedDate: Date
) => Dependant[] = (dependents, selectedDate: Date) => {
    const dependentMap = new Map<string, Dependant>()
    dependents.forEach((dep) => {
        const key = getDependentUniqueKeyByStatus(dep)
        const isNew = !dependentMap.has(key)

        const { lastStatusChange, schedule, status } = dep
        const ninetyDays = moment().subtract(90, 'd')
        const suspendedWithinTime = moment(lastStatusChange).isAfter(ninetyDays)
        const active = status === 'active'
        const suspended = status === 'suspended'

        const isActive = active || (suspended && suspendedWithinTime)
        const savedDependent = dependentMap.get(key)
        const isCurrentActive = shouldReplaceSavedDependent(dep, selectedDate, savedDependent)
        /*
            Use the enrollment date check in case of temp transfer.
            The schedules would match exactly when
            a. the dependent is enrolled in a single center(full or partial) and then temp transferred
            b. the dependent has a multi-center schedule and one of the centers is transferred to a whole new center
            The schedule would overlap when the multi-center scheduled dependent is temporarily transferred
            to one of their existing centers.
            A withdrawn profile in the temp transferred center will get activated and the dates get updated
            if the temp transfer is initiated to that center
        */
        const isLatestEnrolledCentre = savedDependent
            && savedDependent.status === 'active'
            && savedDependent.schedule.some(s => schedule.includes(s))
            ? moment(dep.enrollmentDate).isSameOrAfter(savedDependent.enrollmentDate)
            : true
        const shouldAdd = ((isNew && isActive) || (!isNew && isCurrentActive)) && isLatestEnrolledCentre
        const isAfterEnrollmentDate = moment(selectedDate).isSameOrAfter(dep?.enrollmentDate)

        if (shouldAdd && isAfterEnrollmentDate) {
            dependentMap.set(key, dep)
        }
    })

    return Array.from(dependentMap.values())
}

const getAllIds: (depeandntList: Dependant[], dep: Dependant) => ScheduledDependent[] = (
    depeandntList,
    dep
) => {
    const idList: ScheduledDependent[] = []
    depeandntList.forEach((a) => {
        if (
            a.firstName.toLowerCase() === dep.firstName.toLowerCase() &&
            a.lastName.toLowerCase() === dep.lastName.toLowerCase() &&
            a.age === dep.age
        ) {
            idList.push({ centerId: a.centerId, dependentId: a.id })
        }
    })
    return idList
}

const aggregateMealEntries: (entries: MealEntry[]) => MealEntry[] = (entries) => {
    const meals = []
    const addedMeals: string[] = []

    for (let x = 0; x < entries.length; x++) {
        let initialFoodNote = ''
        if (entries[x].isFromParent) {
            initialFoodNote =
                entries[x].type !== 'Fooddrop' && entries[x].eaten
                    ? titleCase(entries[x].eaten) + ' of the ' + entries[x].food
                    : entries[x].food
        } else {
            initialFoodNote =
                entries[x].eaten !== null && entries[x].eaten !== undefined
                    ? titleCase(entries[x].eaten) + ' of the ' + entries[x].food
                    : entries[x].food
        }

        const newMeal: MealEntry = {
            ...entries[x],
            note: entries[x].note ? titleCase(entries[x].note) : '',
            multipleFoods: false,
            multipleFoodsNote: initialFoodNote
        }

        const mealsArr = entries.filter(
            (m) =>
                m.capturedAt?.toISOString() === entries[x].capturedAt?.toISOString() &&
                entries[x].id !== m.id
        )

        if (mealsArr.length > 0 && !addedMeals.includes(entries[x].id)) {
            mealsArr.forEach((m, index) => {
                newMeal.multipleFoods = true
                newMeal.note += m.note ? (newMeal.note ? '\n' : '') + titleCase(m.note) : ''
                if (newMeal.isFromParent && m.food) {
                    newMeal.multipleFoodsNote +=
                        newMeal.multipleFoodsNote && m.eaten
                            ? `, ${titleCase(m.eaten)} of the ${m.food}`
                            : m.food
                            ? `, ${m.food}`
                            : ''
                } else {
                    newMeal.multipleFoodsNote =
                        newMeal.multipleFoodsNote +
                        (m.eaten
                            ? ', ' + titleCase(m.eaten) + ' of the ' + m.food
                            : m.food
                            ? `, ${m.food}`
                            : '')
                }

                addedMeals.push(m.id)
                addedMeals.push(entries[x].id)
            })
        } else if (!addedMeals.includes(entries[x].id)) {
            meals.push(entries[x])
        }

        if (newMeal.multipleFoods) {
            meals.push(newMeal)
        }
    }
    return meals
}

const validateCheckIn: (currentId: string, scheduledId: string) => boolean = (
    currentId,
    scheduledId
) => {
    const result = currentId === scheduledId ? true : false
    return result
}

export const getEarliestMemory: (dependantList: Dependant[]) => Date = (dependantList) => {
    return dependantList.reduce((acc, d) => {
        return d.earliestMemory && d.earliestMemory < acc ? d.earliestMemory : acc
    }, moment().toDate())
}

export const DAYS_OF_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const DependantReducer: ActionReducer<DependantState> = createReducer(
    initialState,
    on(loadDependantSuccess, (state: DependantState, { dependantList }) => {
        const today = moment().format('dd')
        const earliestMemory = getEarliestMemory(dependantList.slice())

        const allDependents = dependantList.map((dep) => {
            const multipleIds = getAllIds(dependantList, dep)
            const scheduledIds: DependentScheduleMap = {}

            DAYS_OF_WEEK.forEach((day) => {
                const scheduledDep = dependantList.find((d) => {
                    return (
                        getDependentName(d) === getDependentName(dep) &&
                        d.schedule.includes(day) &&
                        d.id === dep.id
                    )
                })
                scheduledIds[day] = {
                    centerId: scheduledDep ? scheduledDep.centerId : '',
                    dependentId: scheduledDep ? scheduledDep.id : ''
                }
            })

            const schedule = scheduledIds[today]

            return {
                ...dep,
                id: schedule.dependentId !== '' ? schedule.dependentId : dep.id,
                centerId: schedule.centerId !== '' ? schedule.centerId : dep.centerId,
                scheduledCenterIds: scheduledIds,
                earliestMemory: earliestMemory,
                multipleIds: multipleIds
            } as Dependant
        })

        const allWithdrawnDependents = allDependents.filter(
            (a: Dependant) => a.status === 'suspended'
        )
        return {
            ...state,
            dependantList: allDependents,
            withdrawnChildrenCount: allWithdrawnDependents.length
        }
    }),

    on(
        loadDependantByDateSuccess,
        (state: DependantState, { dependantListDate, dependantListNextDate, date, centers }) => {
            const allDependents = state.dependantList.map((dep) => {
                const todayDep = dependantListDate.find((d) => d.id === dep.id)
                const nextDep = dependantListNextDate?.find((d) => d.id === dep.id)

                if(dep.status === 'scheduled') {
                    const scheduleDate = moment(dep.enrollmentDate).format('yyyy-MM-DD')
                    return {
                        ...dep,
                        scheduleDate: scheduleDate,
                        nextScheduleDate: scheduleDate
                    }
                }

                return {
                    ...dep,
                    scheduleDate: todayDep ? todayDep.scheduledFor : dep.scheduleDate,
                    nextScheduleDate: nextDep ? nextDep.scheduledFor : dep.nextScheduleDate
                }
            })

            return {
                ...state,
                dependantList: allDependents,
                dependantListDate: {
                    ...state.dependantListDate,
                    [date]: dependantListDate
                },
                dependantListNextDate: dependantListNextDate ?? state.dependantListNextDate,
                centers: centers
            }
        }
    ),

    on(loadActivityFeedSuccess, (state: DependantState, { date, activityFeed, dependantAttendance }) => {
        if (activityFeed === null) {
            return { ...state }
        }
        const feedDate = DateUtil.ignoreTZ(new Date(date))

        const teacherMeals = aggregateMealEntries(activityFeed.meals.filter((m) => !m.isFromParent))
        const todaysFoodNotes = aggregateMealEntries(
            activityFeed.meals.filter(
                (m) =>
                    m.isFromParent &&
                    m.type === 'Fooddrop' &&
                    (m.food?.length > 0 || m.note?.length > 0)
            )
        )
        const foodEatenNotes = aggregateMealEntries(
            activityFeed.meals.filter(
                (m) =>
                    m.isFromParent &&
                    m.type === 'Food' &&
                    (m.food?.length > 0 || m.note?.length > 0 || m.entryTime)
            )
        )
        const liquids = activityFeed.meals.filter(
            (m) => m.isFromParent && m.type !== 'Fooddrop' && m.type !== 'Food'
        )
        const meals = teacherMeals.concat(todaysFoodNotes).concat(foodEatenNotes).concat(liquids)

        const activityObservations = activityFeed.observations.filter((m: any) => !(m.createdInMapp || m.visibility === 'portfolio'))
        const addDomainDetails = activityObservations.map((o) => ({
            ...o,
            selections: o.selections.map((s) => ({
                ...s,
                attribute:
                    state.activityFeedDomain.attributeList.find((a) => a.id === s.attributeId)
                        ?.name || '',
                domain:
                    state.activityFeedDomain.domainList.find((d) => d.id === s.domainId)?.name ||
                    '',
                progression:
                    state.activityFeedDomain.progressionList.find((p) => p.id === s.progressionId)
                        ?.name || ''
            }))
        }))

        const groupedNotes = [
            ...activityFeed.pleaseBringReminders,
            ...activityFeed.teacherNotes
        ].sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())

        const attendanceItems = [ ...activityFeed.attendanceItems ]
        const filteredAttendance = dependantAttendance?.filter(data => ['in', 'out'].find((a) => a === data.status))
        filteredAttendance?.forEach(item => {
            if (item.outTime) {
                attendanceItems.unshift(
                    new AttendanceEntry({
                        entry_time: item.outTime,
                        type: 'pickup'
                    })
                )
            }
            if (item.inTime) {
                attendanceItems.unshift(
                    new AttendanceEntry({
                        entry_time: item.inTime,
                        type: 'checkin'
                    })
                )
            }
        })

        activityFeed = {
            ...activityFeed,
            meals: meals,
            observations: addDomainDetails,
            groupedNotes: groupedNotes,
            attendanceItems
        }

        const activityFeedList = ([] as unknown[]).concat(
            attendanceItems,
            activityFeed.teacherNotes,
            activityFeed.bathrooms,
            activityFeed.naps,
            activityFeed.meals,
            activityFeed.solids,
            activityFeed.medications,
            activityFeed.observations,
            activityFeed.activities,
            activityFeed.snapshots,
            activityFeed.learningAtHomes,
            activityFeed.medicationEntries,
            activityFeed.pleaseBringReminders
        ) as ActivityFeedEntry[]

        const dependentIdscheduled =
            state.dependantList.length === 1 ? state.dependantList[0].id : activityFeed.dependantId

        const checkInDependantSchedule = validateCheckIn(
            activityFeed.dependantId,
            dependentIdscheduled
        )

        const dependantId = checkInDependantSchedule
            ? activityFeed.dependantId
            : dependentIdscheduled

        return {
            ...state,
            centerIdCheckIn: {
                ...state.centerIdCheckIn,
                [dependantId]: {
                    ...state.centerIdCheckIn[dependantId],
                    [feedDate.toISOString().split('T')[0]]: activityFeed.centerId
                }
            },
            activityFeed: {
                ...state.activityFeed,
                [date]: {
                    ...state.activityFeed[date],
                    [dependantId]: {
                        group: activityFeed,
                        timeline: activityFeedList
                            .slice()
                            .sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime())
                    }
                }
            }
        }
    }),
    on(loadActivityFeedDomainSuccess, (state: DependantState, { activityFeedDomain }) => ({
        ...state,
        activityFeedDomain
    })),
    on(markDate, (state: DependantState, { date }) => ({
        ...state,
        dependantList: state.dependantList.map((d) => {
            const scheduledDays = Object.keys(d.scheduledCenterIds).filter(
                (day) => d.scheduledCenterIds[day] && !!d.scheduledCenterIds[day].centerId
            )

            return {
                ...d,
                isScheduledToday: Dependant.getScheduledForToday(scheduledDays, date)
            }
        }),
        activityFeedDate: DateUtil.localToUtc(date)
    })),
    on(setPaginationCompleted, (state: DependantState, { completed }) => ({
        ...state,
        paginationCompleted: completed
    })),
    on(selectedDependant, (state: DependantState, { dependent }) => ({
        ...state,
        selectedDependant: dependent
    }))
)
